import { Form } from "vee-validate";
import * as yup from "yup";
import changeLanguage from "@/components/changeLanguage.vue";
import InputWithValidation from "@/components/inputWidthvalidate.vue";
import { URL } from "@/api/password";

export default {
  name: "resetPassword",
  components: {
    Form,
    changeLanguage,
    InputWithValidation,
  },

  data() {
    return {
      formData: {
        password: "",
        confirm_password: "",
      },
    };
  },

  setup() {
    const resetPassword = yup.object({
      password: yup
        .string()
        .min(8, "Tối thiểu 8 ký tự")
        .required("Mật khẩu là một trường bắt buộc"),
      confirm_password: yup
        .string()
        .oneOf([yup.ref("password")], "Mật khẩu không khớp")
        .required("Xác nhận mật khẩu là một trường bắt buộc"),
    });

    return {
      resetPassword,
    };
  },

  methods: {
    async resetPass() {
      const data = {};
      data.uid = this.$route.params.uid;
      data.token = this.$route.params.token;
      data.password = this.formData.password;
      data.password_confirm = this.formData.confirm_password;

      const result = await this.$request({
        url: URL.RESET_PASSWORD,
        method: "POST",
        data,
      });
      const res = result.data;
      var status = "success";
      if (!res.success) {
        if (res.code == 403) {
          status = "warning";
        }
      }
      this.$swal({
        text: res.messages,
        icon: status,
        timer: "2000",
        width: "450",
        padding: "10px",
      }).then(() => {
        this.$router.push({ name: "login" });
      });
    },
  },
};
